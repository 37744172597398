<div class="row justify-content-center">
    <div class="col-12 col-lg-3 col-sm-6">
        <div class="form-group">
            <label for="title">Name</label>
            <input id="title" type="text" [ngModel]="query.name" (ngModelChange)="setName($event)"
                class="form-control" />
        </div>
    </div>
    <div class="col-12 col-lg-3 col-sm-6">
        <div class="form-group">
            <label for="content">Email</label>
            <input id="content" type="text" [ngModel]="query.email" (ngModelChange)="setEmail($event)"
                class="form-control" />
        </div>
    </div>
    <div class="col-12 col-lg-3 col-sm-6">
        <div class="form-group">
            <label for="content">Code</label>
            <input id="content" type="text" [ngModel]="query.code" (ngModelChange)="setCode($event)"
                class="form-control" />
        </div>
    </div>
    <div class="col-12 col-lg-3 col-sm-6">
        <div class="form-group">
            <label for="type">Type</label>
            <select id="type" [ngModel]="query.type" (ngModelChange)="setType($event)" class="form-control">
                <option [ngValue]="undefined">Any</option>
                <option *ngFor="let option of typeOptions" [ngValue]="option">
                    {{ option | breakUpString }}
                </option>
            </select>
        </div>
    </div>
    <div class="col-12 col-lg-3 col-sm-6">
        <div class="form-group">
            <label for="orderDateFrom">Order Date From</label>
            <div class="input-group">
                <input class="form-control" placeholder="Any" name="orderDateFrom" [ngModel]="query.orderDateFrom"
                    (ngModelChange)="setOrderDateFrom($event)" ngbDatepicker [maxDate]="query.orderDateTo"
                    #orderDateFrom="ngbDatepicker" />
                <div class="input-group-append">
                    <button class="btn btn-danger" (click)="resetOrderDateFrom()">
                        <span class="fa fa-times"></span>
                    </button>
                    <button class="btn btn-light calendar" (click)="orderDateFrom.toggle()" type="button"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3 col-sm-6">
        <div class="form-group">
            <label for="orderDateTo">Order Date To </label>
            <div class="input-group">
                <input class="form-control" placeholder="Any" name="orderDateTo" [ngModel]="query.orderDateTo"
                    (ngModelChange)="setOrderDateTo($event)" ngbDatepicker [minDate]="query.orderDateFrom"
                    #orderDateTo="ngbDatepicker" />
                <div class="input-group-append">
                    <button class="btn btn-danger" (click)="resetOrderDateTo()">
                        <span class="fa fa-times"></span>
                    </button>
                    <button class="btn btn-light calendar" (click)="orderDateTo.toggle()" type="button"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3 col-sm-6">
        <div class="form-group">
            <label for="discountDateFrom">Discount Date From</label>
            <div class="input-group">
                <input class="form-control" placeholder="Any" name="discountDateFrom" [ngModel]="query.discountDateFrom"
                    (ngModelChange)="setDiscountDateFrom($event)" ngbDatepicker [maxDate]="query.discountDateTo"
                    #discountDateFrom="ngbDatepicker" />
                <div class="input-group-append">
                    <button class="btn btn-danger" (click)="resetDiscountDateFrom()">
                        <span class="fa fa-times"></span>
                    </button>
                    <button class="btn btn-light calendar" (click)="discountDateFrom.toggle()" type="button"></button>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-lg-3 col-sm-6">
        <div class="form-group">
            <label for="discountDateTo">Discount Date To </label>
            <div class="input-group">
                <input class="form-control" placeholder="Any" name="discountDateTo" [ngModel]="query.discountDateTo"
                    (ngModelChange)="setDiscountDateTo($event)" ngbDatepicker [minDate]="query.discountDateFrom"
                    #discountDateTo="ngbDatepicker" />
                <div class="input-group-append">
                    <button class="btn btn-danger" (click)="resetDiscountDateTo()">
                        <span class="fa fa-times"></span>
                    </button>
                    <button class="btn btn-light calendar" (click)="discountDateTo.toggle()" type="button"></button>
                </div>
            </div>
        </div>
    </div>
    <nba-optional-boolean-dropdown class="col-12 col-lg-3 col-sm-6" [model]="query.redeemed" title="Redeemed?"
        (valueChanged)="setRedeemed($event)">
    </nba-optional-boolean-dropdown>
</div>