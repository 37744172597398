import { Component, HostBinding } from '@angular/core';

import { ToastService } from './toast.service';

@Component({
  selector: 'nba-toasts',
  template: `
    <nba-toast-display *ngFor="let toast of toastService.toasts">
    </nba-toast-display>
  `,
})
export class ToastsContainerComponent {
  @HostBinding('class.ngb-toasts') toasts = true;

  constructor(public toastService: ToastService) {}
}
