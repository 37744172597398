import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'nba-inline-item-pager-item',
  templateUrl: './inline-item-pager-item.component.html',
  styleUrls: ['./inline-item-pager-item.component.scss'],
})
export class InlineItemPagerItemComponent implements OnInit {
  @Input()
  show: boolean;

  @Input()
  disabled: boolean;

  get enabled() {
    return !this.disabled;
  }

  @Input()
  pageNumber: number;

  @Input()
  text: string;

  @Output()
  pageUpdated = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  onPageUpdated() {
    if (this.enabled) {
      this.pageUpdated.emit(this.pageNumber);
    }
  }
}
