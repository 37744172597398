import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgbDateParserFormatter, NgbModalConfig, NgbModalModule, NgbToastModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { LoadingModule } from '../loading/loading.module';
import { AdminModalComponent } from './admin-modal/admin-modal.component';
import { AdminButtonDirective } from './admin-page/admin-button.directive';
import { AdminPageComponent } from './admin-page/admin-page.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ExportButtonComponent } from './export-button/export-button.component';
import { InlineInputEditComponent } from './inline-input-edit/inline-input-edit.component';
import { InlineTagsEditComponent } from './inline-tags-edit/inline-tags-edit.component';
import { DataInterceptor } from './interceptors/data-interceptor';
import { AngularDateHttpInterceptor } from './interceptors/date-interceptor';
import { NavItemsComponent } from './nav-items/nav-items.component';
import { OptionalBooleanDropdownComponent } from './optional-boolean-dropdown/optional-boolean-dropdown.component';
import { BreakUpStringPipe } from './pipes/break-up-string.pipe';
import { SearchFilterComponent } from './search-filter/search-filter.component';
import { UkDateParserFormatter } from './uk-date-parser-formatter';

@NgModule({
  declarations: [
    BreadcrumbComponent,
    AdminPageComponent,
    AdminButtonDirective,
    BreakUpStringPipe,
    AdminModalComponent,
    SearchFilterComponent,
    InlineInputEditComponent,
    OptionalBooleanDropdownComponent,
    NavItemsComponent,
    InlineTagsEditComponent,
    ExportButtonComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbToastModule,
    CKEditorModule,
    FormsModule,
    LoadingModule,
    NgSelectModule,
  ],
  exports: [
    BreadcrumbComponent,
    ReactiveFormsModule,
    NgbModalModule,
    AdminPageComponent,
    AdminModalComponent,
    InlineInputEditComponent,
    InlineTagsEditComponent,
    SearchFilterComponent,
    AdminButtonDirective,
    BreakUpStringPipe,
    CKEditorModule,
    OptionalBooleanDropdownComponent,
    ExportButtonComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: DataInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AngularDateHttpInterceptor,
      multi: true,
    },
    {
      provide: NgbModalConfig,
      useValue: { backdrop: 'static' } as NgbModalConfig,
    },
    { provide: NgbDateParserFormatter, useClass: UkDateParserFormatter },
  ],
})
export class SharedModule {}
