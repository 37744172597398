<div class="form-group">
  <label [for]="id"> {{ title }} </label>
  <select
    [id]="id"
    [ngModel]="model"
    (ngModelChange)="valueChanged.emit($event)"
    class="form-control"
  >
    <option [ngValue]="undefined">All</option>
    <option [ngValue]="true">Yes</option>
    <option [ngValue]="false">No</option>
  </select>
</div>
