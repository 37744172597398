import { Component, HostBinding, Input, OnInit, TemplateRef } from '@angular/core';

import { ToastService } from '../toast.service';

@Component({
  selector: 'nba-toast-display',
  templateUrl: './toast-display.component.html',
  styleUrls: ['./toast-display.component.scss'],
})
export class ToastDisplayComponent implements OnInit {
  constructor(public toastService: ToastService) {}
  @HostBinding('class.ngb-toasts') toasts = true;
  autohide = true;

  @Input()
  delay = 5000;

  isTemplate(toast) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  ngOnInit(): void {}
}
