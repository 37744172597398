import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PagedCollection } from '../item-pager/paged-collection';
import { RequestService } from '../shared/request.service';
import { CourseNotificationInfo } from './dtos/course-notification-info';
import { CourseNoticationSearchQuery } from './dtos/course-notification-search-query';

@Injectable({
  providedIn: 'root',
})
export class CourseNotificationsService {
  constructor(private requestService: RequestService) {}

  search(
    request: CourseNoticationSearchQuery
  ): Observable<PagedCollection<CourseNotificationInfo>> {
    return this.requestService
      .adminQuery<PagedCollection<CourseNotificationInfo>>(
        'SearchCourseNotifications',
        request
      )
      .pipe(map((data) => PagedCollection.parseData(data)));
  }

  public getFilter(query: CourseNoticationSearchQuery) {
    const val = this.getFilterData(query);
    if (!val.activeKeys.length) {
      return '';
    }
    return `?${val.activeKeys
      .map((key) => key + '=' + val.data[key])
      .join('&')}`;
  }

  getTotal() {
    return this.requestService.adminQuery<number>(
      'GetTotalCourseNotifications',
      {}
    );
  }

  getFilterObject(query: CourseNoticationSearchQuery) {
    const val = this.getFilterData(query);
    if (!val.activeKeys.length) {
      return undefined;
    }
    return val.data;
  }

  private getFilterData(query: CourseNoticationSearchQuery) {
    const queryData: any = { ...query };
    if (queryData.dateFrom) {
      queryData.dateFrom = this.getParamDate(queryData.dateFrom);
    }
    if (queryData.dateTo) {
      queryData.dateTo = this.getParamDate(queryData.dateTo);
    }
    const activeKeys = Object.keys(queryData).filter(
      (key) => queryData[key] !== undefined && queryData[key] !== null
    );

    const data = {};
    activeKeys.forEach((key) => (data[key] = queryData[key]));

    return { activeKeys, data };
  }

  private getParamDate(date: NgbDateStruct): string {
    if (!date) {
      return undefined;
    }
    return `${date.year}-${date.month}-${date.day}`;
  }
}
