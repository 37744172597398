import { Component, Input, OnInit } from '@angular/core';

import { IPagedCollection } from '../paged-collection';

@Component({
  selector: 'nba-item-pager',
  templateUrl: './item-pager.component.html',
  styleUrls: ['./item-pager.component.scss'],
})
export class ItemPagerComponent implements OnInit {
  @Input()
  items: IPagedCollection;

  routeData: string[] = [];

  constructor() {}

  ngOnInit() {}

  getRouterPageNumber(page: number) {
    if (page <= 1) {
      return undefined;
    }
    return page;
  }
}
