<div class="card">
  <div class="card-body">
    <span class="h5 card-title">Failed Emails</span>
    <button class="btn btn-info float-right mb-2" (click)="getData()">
      Refresh
    </button>
    <ng-container *ngIf="!loading">
      <table
        class="table table-striped"
        *ngIf="emails && emails.data.length > 0; else noEmails"
      >
        <thead>
          <tr>
            <th>From Address</th>
            <th>To Address</th>
            <th>Subject</th>
            <th>Date Created</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let email of emails.data">
            <td class="align-middle">
              {{ email.fromAddress }}
            </td>
            <td class="align-middle">
              {{ email.toAddress }}
            </td>
            <td class="align-middle">
              {{ email.subject }}
            </td>
            <td class="align-middle">
              {{ email.createdOn | date: "short" }}
            </td>
            <td class="text-right align-middle">
              <div class="btn-group">
                <a
                  class="btn btn-secondary"
                  [routerLink]="['/email-messages', email.id, 'preview']"
                  >Preview</a
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <nba-item-pager
        [items]="emails"
        (pageUpdated)="onPageUpdated()"
      ></nba-item-pager>
      <ng-template #noEmails
        ><div *ngIf="!loading" class="alert alert-info text-center mt-4 mb-2">
          No Failed Emails
        </div></ng-template
      >
    </ng-container>
    <nba-loading *ngIf="loading"></nba-loading>
  </div>
</div>
