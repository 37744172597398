import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import { Observable, of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';

import { StorageService } from '../auth/storage-service';
import { UserService } from '../auth/user.service';

@Injectable({
  providedIn: 'root',
})
export class RequestService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private oauthService: OAuthService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {}

  adminQuery<TResponse>(type: string, data: any) {
    return this.mapForCurrentUser((user) => {
      return this.http.get<TResponse>('/admin/query', {
        responseType: 'json',
        params: { type, data: data ? JSON.stringify(data) : undefined },
        headers: this.getUserHeaders(user),
      });
    });
  }

  adminCommand<TResponse>(type: string, data: any) {
    return this.mapForCurrentUser((user) => {
      return this.http.post<TResponse>(
        '/admin/command',
        { type, data },
        { responseType: 'json', headers: this.getUserHeaders(user) }
      );
    });
  }
  adminFiles(folderId: string, files: File[]) {
    // You could upload it like this:
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('file', file, file.name);
    });

    return this.mapForCurrentUser((user) => {
      return this.http.post(`/admin/file/${folderId}`, formData, {
        headers: this.getUserHeaders(user),
      });
    });
  }

  adminDownload<TResponse>(type: string, data: any) {
    if (!isPlatformBrowser(this.platformId)) {
      return of<TResponse>();
    }
    return this.mapForCurrentUser((user) => {
      if (StorageService.isSSR) {
        return of(null);
      }
      return this.http.get('/admin/download', {
        responseType: 'blob',
        params: { type, data: data ? JSON.stringify(data) : undefined },
        headers: this.getUserHeaders(user),
      });
    });
  }

  private mapForCurrentUser<T>(func: (user: UserInfo) => Observable<T>) {
    // take(1) is required here so that the current user 'completes'
    return this.userService.currentUser$.pipe(take(1), switchMap(func));
  }

  private getUserHeaders(user: UserInfo) {
    if (!UserService.isAuthenticated(user)) {
      return undefined;
    }
    const data = {
      Authorization: this.oauthService.authorizationHeader(),
    };
    return data;
  }
}
