export const environment = {
  production: true,
  apiUrl: 'https://licence-api-nb.preview-site.website',
  publicUrl: 'https://licence-admin-nb.preview-site.website',
  idealPostcodesApiKey: 'ak_hr19shxjfa0mtFMs56ZgD2xb5gqo2',
  clientSettings: {
    authority: 'https://licence-auth-nb.preview-site.website',
    client_id: 'admin',
    scope: 'openid profile email admin-api',
  },
};
