import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LoginService } from './login.service';
import { StorageService } from './storage-service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class IsLoggedInGuard implements CanActivate {
  constructor(
    private userService: UserService,
    private loginService: LoginService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const additionalRoles = (route.data?.additionalRoles as string[]) ?? [];

    return this.userService.currentUser$.pipe(
      map((user) => {
        // don't block server side
        if (StorageService.isSSR) {
          return true;
        }
        if (!UserService.isAuthenticated(user)) {
          this.loginService.login(state.url);
          return false;
        }
        if (!UserService.canAccess(user, additionalRoles)) {
          this.loginService.insufficientPermissions(state.url);
          return false;
        }
        return true;
      })
    );
  }
}
