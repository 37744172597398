import { Injectable } from '@angular/core';
import { OAuthService, UserInfo } from 'angular-oauth2-oidc';
import * as dayjs from 'dayjs';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';

import { AppState } from '../app-state';
import { StorageService } from './storage-service';

// import { User } from 'oidc-client';
const userIdKey = 'user.userIdKey';
const userKey = 'user.userKey';
@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private oauthService: OAuthService) {}
  get currentUser$(): Observable<UserInfo> {
    if (AppState.isUserInitialized) {
      return AppState.user$;
    }
    return this.loadUserProfile();
  }
  get currentUserValue(): UserInfo {
    return AppState.user$.value;
  }

  static isAuthenticated(user: UserInfo): boolean {
    if (!user) {
      return false;
    }
    const expiresAt = StorageService.getItem('expires_at');
    if (!expiresAt) {
      return false;
    }
    const expiresAtValue = parseInt(expiresAt, 10);
    if (isNaN(expiresAtValue) || expiresAtValue <= 0) {
      return false;
    }
    return dayjs().isBefore(dayjs(expiresAtValue));
  }

  static getUserRoles(user: UserInfo): string[] {
    return user != null && this.isAuthenticated && user.role
      ? Array.isArray(user.role)
        ? user.role
        : [user.role]
      : [];
  }

  static userIsInRole(user: UserInfo, role: string) {
    const roles = this.getUserRoles(user);
    return roles.some((x) => x === role);
  }
  static canImpersonate(user: UserInfo) {
    return UserService.userIsInRole(user, 'Impersonator');
  }
  static isAdmin(user: UserInfo) {
    return UserService.userIsInRole(user, 'WebsiteAdmin');
  }
  // static isInARole(user: UserInfo, roles: string[]) {
  //   return roles.some((role) => UserService.userIsInRole(user, role));
  // }
  static canAccess(user: UserInfo, additionalRoles: string[]) {
    if (this.isAdmin(user)) return true;
    return additionalRoles.some((role) => UserService.userIsInRole(user, role));
  }
  static getAuthorizationHeaderValue(user: UserInfo): string {
    const isAuthenticated = this.isAuthenticated(user);
    return !isAuthenticated ? null : `${user.token_type} ${user.access_token}`;
  }
  static getUserName(user: UserInfo): string {
    return user?.name || '';
  }
  static getUserId(user: UserInfo) {
    if (user) {
      return user?.sub;
    }
    return this.getLocalUserId();
  }

  static getLocalUserId() {
    if (StorageService.hasStorage) {
      const value = StorageService.getItem(userIdKey);
      if (value) {
        return value;
      }
      const newValue = uuidv4();
      StorageService.setItem(userIdKey, newValue);
      return newValue;
    }
    return uuidv4();
  }
  private loadUserProfile() {
    if (!this.oauthService.hasValidAccessToken()) {
      AppState.user$.next(null);
      return AppState.user$;
    } else {
      return from(
        this.oauthService
          .loadDiscoveryDocument()
          .then((_) => {
            return this.oauthService.loadUserProfile();
          })
          .then((user: any) => {
            AppState.user$.next(user?.info ?? null);
          })
        // .then((_) => this.user$)
      ).pipe(mergeMap((_) => AppState.user$));
    }
  }
  // private static getUserFromStorage(): UserInfo {
  //   const userData = UserService.storage.getItem(userKey);
  //   if (userData) {
  //     return JSON.parse(userData);
  //   }
  //   return null;
  // }
  setUser(user: UserInfo) {
    AppState.user$.next(user);
  }
}
