<div class="card">
  <div class="card-body">
    <span class="h5 card-title">Job listings to approve</span>
    <button class="btn btn-info float-right mb-2" (click)="getData()">
      Refresh
    </button>
    <ng-container *ngIf="!loading">
      <div class="col-12 d-flex justify-content-center">
        <div class="mt-2 mb-3 text-center border border-dark shadow p-4">
          <p class="mt-2 mb-2 text-center h2">
            {{ jobCount$ | async }}
          </p>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center">
        <button
          class="btn btn-primary"
          [routerLink]="['/job-listings']"
          [queryParams]="{ status: 'Pending' }"
        >
          View jobs to approve
        </button>
      </div>
    </ng-container>
    <nba-loading *ngIf="loading"></nba-loading>
  </div>
</div>
