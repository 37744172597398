import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nba-item-pager-item',
  templateUrl: './item-pager-item.component.html',
  styleUrls: ['./item-pager-item.component.scss'],
})
export class ItemPagerItemComponent implements OnInit {
  @Input()
  show: boolean;

  @Input()
  disabled: boolean;

  get enabled() {
    return !this.disabled;
  }

  @Input()
  pageNumber: number;

  @Input()
  text: string;

  constructor() {}

  ngOnInit(): void {}

  get routeData() {
    if (this.enabled) {
      return [];
    }
    return undefined;
  }

  get queryParams() {
    if (this.enabled && this.pageNumber) {
      return { page: this.pageNumber };
    }
    return undefined;
  }
}
