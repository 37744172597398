import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'nba-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
  constructor() {}

  @Input()
  ellipsisClass = 'border-dark';

  @Input()
  textClass = '';

  @Input()
  text = '';

  @Input()
  minHeight = '200px';

  ngOnInit() {}
}
