import { Route } from '@angular/router';

import { IsLoggedInGuard } from './auth/is-logged-in.guard';
import { LoginComponent } from './login/login.component';

interface AdminRoute extends Route {
  showInNav: boolean;
  displayName: string;
  icon?: string;
}

export const AppRoutes: AdminRoute[] = [
  {
    path: '',
    component: LoginComponent,
    showInNav: false,
    displayName: 'Home',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth-ui/auth-ui.module').then((x) => x.AuthUiModule),
    showInNav: false,
    displayName: 'Auth',
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((x) => x.PagesModule),
    canActivate: [IsLoggedInGuard],
    data: {
      additionalRoles: ['CMSUser'],
    },
    showInNav: true,
    displayName: 'Pages',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'page-enhancements',
    loadChildren: () =>
      import('./page-enhancements/page-enhancements.module').then(
        (x) => x.PageEnhancementsModule
      ),
    data: {
      additionalRoles: ['CMSUser'],
    },
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Page Enhancements',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'media',
    loadChildren: () =>
      import('./media/media.module').then((x) => x.MediaModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Media',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'job-listings',
    loadChildren: () =>
      import('./job-listings/job-listings.module').then(
        (x) => x.JobListingsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Job Listings',
    icon: 'fas fa-list',
  },
  {
    path: 'authors',
    loadChildren: () =>
      import('./authors/authors.module').then((x) => x.AuthorsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Authors',
    icon: 'fas fa-user-edit',
  },
  {
    path: 'blogs',
    loadChildren: () =>
      import('./blogs/blogs.module').then((x) => x.BlogsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Blogs',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'kiss-summaries',
    loadChildren: () =>
      import('./kiss-summaries/kiss-summaries.module').then(
        (x) => x.KissSummariesModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'KISS Summaries',
    icon: 'fas fa-notes-medical',
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./products/products.module').then((x) => x.ProductsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Products',
    icon: 'fas fa-boxes',
  },
  {
    path: 'sold-out-notifications',
    loadChildren: () =>
      import('./sold-out-notifications/sold-out-notifications.module').then(
        (x) => x.SoldOutNotificationsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Sold Out Notifications',
    icon: 'fas fa-bell',
  },
  {
    path: 'tags',
    loadChildren: () => import('./tags/tags.module').then((x) => x.TagsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Tags',
    icon: 'fas fa-tags',
  },
  {
    path: 'discounts',
    loadChildren: () =>
      import('./discounts/discounts.module').then((x) => x.DiscountsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Discounts',
    icon: 'fas fa-tags',
  },
  {
    path: 'users',
    loadChildren: () =>
      import('./users/users.module').then((x) => x.UsersModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Users',
    icon: 'fas fa-user',
  },
  {
    path: 'organisations',
    loadChildren: () =>
      import('./organisations/organisations.module').then((x) => x.OrganisationsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Organisations',
    icon: 'fas fa-building',
  },
  {
    path: 'orders',
    loadChildren: () =>
      import('./orders/orders.module').then((x) => x.OrdersModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Orders',
    icon: 'fas fa-file-alt',
  },
  {
    path: 'vouchers',
    loadChildren: () =>
      import('./vouchers/vouchers.module').then((x) => x.VouchersModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Vouchers',
    icon: 'fas fa-ticket-alt',
  },
  {
    path: 'subscriptions',
    loadChildren: () =>
      import('./subscriptions/subscriptions.module').then(
        (x) => x.SubscriptionsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Subscriptions',
    icon: 'fas fa-redo',
  },
  {
    path: 'locations',
    loadChildren: () =>
      import('./locations/locations.module').then((x) => x.LocationsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Locations',
    icon: 'fas fa-map-pin',
  },
  {
    path: 'testimonials',
    loadChildren: () =>
      import('./testimonials/testimonials.module').then(
        (x) => x.TestimonialsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Testimonials',
    icon: 'far fa-comments',
  },
  {
    path: 'vacancies',
    loadChildren: () =>
      import('./vacancies/vacancies.module').then((x) => x.VacanciesModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Vacancies',
    icon: 'fas fa-briefcase',
  },
  {
    path: 'faqs',
    loadChildren: () => import('./faq/faq.module').then((x) => x.FaqModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'FAQs',
    icon: 'fas fa-question',
  },
  {
    path: 'email-messages',
    loadChildren: () =>
      import('./email-messages/email-messages.module').then(
        (x) => x.EmailMessagesModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Email Messages',
    icon: 'fas fa-comment',
  },
  {
    path: 'course-notifications',
    loadChildren: () =>
      import('./course-notifications/course-notifications.module').then(
        (x) => x.CourseNotificationsModule
      ),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Course Notifications',
    icon: 'fas fa-bell',
  },
  {
    path: 'audits',
    loadChildren: () =>
      import('./audits/audits.module').then((x) => x.AuditsModule),
    canActivate: [IsLoggedInGuard],
    showInNav: true,
    displayName: 'Audits',
    icon: 'fas fa-clipboard-list',
  },
];
