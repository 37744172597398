import { UserAddress } from 'src/app/address/dtos/user-address';

import { OrderDetailData } from './order-detail-data';
import { OrderDiscountDetails } from './order-discount-details';
import { OrderItemDetails } from './order-item-details';
import { OrderPrice } from './order-price';
import { OrderShipping } from './order-shipping';
import { OrderTransaction } from './order-transaction';
import { UserInfo } from './user-info';

export class OrderDetails implements OrderDetailData {
  constructor(data: OrderDetailData) {
    this.id = data.id;
    this.user = data.user;
    this.status = data.status;
    this.displayId = data.displayId;
    this.createdOn = data.createdOn;
    this.billingAddress = data.billingAddress;
    this.shippingAddress = data.shippingAddress;
    this.shipping = data.shipping;
    this.subtotal = data.subtotal;
    this.shippingTotal = data.shippingTotal;
    this.total = data.total;
    this.items = data.items;
    this.transactions = data.transactions;
    this.totalPaid = data.totalPaid;
    this.balance = data.balance;
    this.requiresShipping = data.requiresShipping;
    this.discounts = data.discounts;
    this.invoiceUrl = data.invoiceUrl;
  }
  readonly id: string;
  readonly user: UserInfo;
  readonly status: string;
  readonly displayId: string;
  readonly createdOn: Date;
  readonly billingAddress: UserAddress;
  readonly shippingAddress: UserAddress;
  readonly shipping: OrderShipping;
  readonly subtotal: OrderPrice;
  readonly shippingTotal: OrderPrice;
  readonly total: OrderPrice;
  readonly items: OrderItemDetails[];
  readonly transactions: OrderTransaction[];
  readonly totalPaid: number;
  readonly balance: number;
  readonly requiresShipping: boolean;
  readonly discounts: OrderDiscountDetails[];
  readonly invoiceUrl: string;

  //   get canIssueRefund() {
  //     return this.maxRefundAmount > 0;
  //   }
  //   get maxRefundAmount() {
  //     return this.transactions.reduce(
  //       (prev, curr) => prev + (curr.refundable ? curr.amount : 0),
  //       0
  //     );
  //   }
}
