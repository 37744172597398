<div class="card">
  <div class="card-body">
    <span class="h5 card-title">Orders Not Synced</span>
    <button class="btn btn-info float-right mb-2" (click)="getData()">
      Refresh
    </button>
    <ng-container *ngIf="!loading">
      <div *ngIf="orders && shownOrders.data.length > 0; else noOrders">
        <table class="table table-striped">
          <thead>
            <tr>
              <th>ID</th>
              <th>Created On</th>
              <th>Total</th>
              <th>Sync Status</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let order of shownOrders.data">
              <td class="align-middle">
                {{ order.id }}
              </td>
              <td class="align-middle">
                {{ order.createdOn | date: "short" }}
              </td>
              <td class="align-middle">
                {{ order.total.total | currency: "GBP" }}
              </td>
              <td class="align-middle">
                {{ order.syncStatus | breakUpString }}
              </td>
              <td class="text-right align-middle">
                <div class="btn-group">
                  <a *ngIf="
                    order.syncStatus === 'Error' ||
                    order.syncStatus === 'Syncing'
                  " class="btn btn-warning mr-1" (click)="setNotSynced(order.id)"><i class="fas fa-sync-alt"></i></a>
                  <a class="btn btn-primary" [routerLink]="['/orders', order.id]"><i class="fas fa-eye"></i></a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <nba-inline-item-pager [items]="shownOrders" (pageUpdated)="onPageUpdated($event)"></nba-inline-item-pager>
      </div>
      <ng-template #noOrders>
        <div *ngIf="!loading" class="alert alert-info text-center">
          No Sync Errors
        </div>
      </ng-template>
    </ng-container>
    <nba-loading *ngIf="loading"></nba-loading>
  </div>
</div>