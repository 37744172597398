import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

import { ItemPagerModule } from '../item-pager/item-pager.module';
import { LoadingModule } from '../loading/loading.module';
import { SharedModule } from '../shared/shared.module';
import {
  CourseNotificationsCountReportComponent,
} from './course-notifications-count-report/course-notifications-count-report.component';
import {
  DiscountUsageReportSearchFormComponent,
} from './discount-usage-report-search-form/discount-usage-report-search-form.component';
import { DiscountUsageReportComponent } from './discount-usage-report/discount-usage-report.component';
import { FailedEmailsReportComponent } from './failed-emails-report/failed-emails-report.component';
import { OrdersNotSyncedReportComponent } from './orders-not-synced-report/orders-not-synced-report.component';
import { ProductsNoPriceReportComponent } from './products-no-price-report/products-no-price-report.component';
import { ReportsOverviewComponent } from './reports-overview/reports-overview.component';
import { InvoicesNotSyncedComponent } from './invoices-not-synced/invoices-not-synced.component';
import { JobListingsToApproveReportComponent } from './job-listings-to-approve-report/job-listings-to-approve-report.component';

@NgModule({
  declarations: [
    OrdersNotSyncedReportComponent,
    ReportsOverviewComponent,
    ProductsNoPriceReportComponent,
    FailedEmailsReportComponent,
    CourseNotificationsCountReportComponent,
    JobListingsToApproveReportComponent,
    DiscountUsageReportComponent,
    DiscountUsageReportSearchFormComponent,
    InvoicesNotSyncedComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    LoadingModule,
    RouterModule,
    ItemPagerModule,
    FormsModule,
    NgbDatepickerModule,
  ],
  exports: [ReportsOverviewComponent],
})
export class ReportsModule {}
