<div class="sidebar-header px-5 py-3 sticky-top text-center">
  <h3>NBMedical Admin</h3>
</div>
<ul class="list-unstyled components">
  <li
    class="d-flex align-self-center justify-content-start flex-fill border-bottom"
  >
    <a
      class="align-items-baseline align-self-center d-flex flex-fill justify-content-start p-3"
      routerLink="/"
      (click)="clicked()"
    >
      <i class="fas fa-home mr-2"></i>
      <span> Home</span>
    </a>
  </li>
  <ng-container>
    <li
      class="d-flex align-self-center justify-content-start flex-fill border-bottom"
      *ngFor="let link of links$ | async"
      [ngClass]="{ 'd-lg-none': link.mobileOnly }"
    >
      <a
        *ngIf="!link.onClick"
        class="align-items-baseline align-self-center d-flex flex-fill justify-content-start p-3"
        [routerLink]="link.link"
        routerLinkActive="active"
        (click)="clicked()"
      >
        <i class="{{ link.icon }} mr-2"> </i>
        <span> {{ link.name }}</span>
      </a>
      <a
        *ngIf="link.onClick"
        class="d-flex align-self-center justify-content-center flex-fill p-3 cursor-pointer"
        (click)="link.onClick(); clicked()"
      >
        {{ link.name }}
      </a>
    </li>
  </ng-container>
</ul>
